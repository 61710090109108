<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      spinner="bar-fade-scale"
      color="#1976d2"
      size="128"
      is-full-screen
    />
    <v-layout v-if="ServerError" wrap justify-center>
      <v-flex px-5 pt-5 xs12 sm12 md12 lg12 xl12 text-center>
        <ServerError />
      </v-flex>
    </v-layout>
    <v-layout v-else wrap justify-center>
      <v-flex px-2 pt-2 xs12 sm12 md12 lg12 xl12 text-center>
        <v-card>
          <v-card-title class="elevation-1">
            Pending Products for Approval
            <v-spacer></v-spacer>
            <v-col cols="12" sm="3">
              <v-text-field
                v-model="search"
                solo
                @keyup.enter.native="getData()"
                label="Search"
                single-line
                hide-details
              >
                <template v-slot:append>
                  <v-icon @click="getData()"> mdi-magnify </v-icon>
                </template>
              </v-text-field>
            </v-col> </v-card-title
          ><br />
          <v-layout wrap>
            <v-flex v-for="(item, i) in user" :key="i" md3 pa-4>
              <v-card tile flat
                ><br />
                <v-img
                  contain
                  :src="baseURL + item.photos[0]"
                  height="200px"
                  cover
                ></v-img>
                <v-layout wrap justify-center px-2>
                  <v-flex
                    pt-2
                    xl12
                    text-left
                    style="line-height: 16px; cursor: pointer"
                    @click="info(item._id)"
                  >
                    <span style="font-size: 13px; color: #000000">
                      {{ item.productname }}
                    </span>
                    <br />
                    <span style="font-size: 13px; color: #000000">
                      {{ item.subcategory.name }}
                    </span>
                    <br />
                    <span style="font-size: 13px; color: #000000">
                      {{ item.seller.organization }}
                    </span>
                    <br />
                    <span style="font-size: 13px; color: #000000">
                      Rs. {{ item.price }}
                    </span>
                    <span
                      class="pl-12 text-decoration-line-through"
                      style="font-size: 13px; color: #ff0000"
                      v-if="item.offer > 0"
                    >
                      Rs. {{ item.orginalprice }}
                    </span>
                    <br />
                    <span>
                      <v-rating
                        v-model="item.rating"
                        color="#FFD341"
                        background-color="#e0e0e0"
                        empty-icon="$ratingFull"
                        half-increments
                        readonly
                        hover
                        small
                      ></v-rating>
                    </span>
                    <br />
                    <!-- <span style="font-size: 12px; color: #6b6b6b">
                          {{ product.endsin }}
                        </span> -->
                  </v-flex>
                </v-layout>
              </v-card>
            </v-flex>
          </v-layout>
          <div class="pt-2">
            <v-pagination
              :length="pages"
              :total-visible="7"
              v-model="currentPage"
              color="#FF0000"
            ></v-pagination>
          </div>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      appLoading: false,
      dialogDelete: false,
      search: "",
      currentPage: 1,
      widthOfCard: "250px",
      pages: 0,
      count: 12,
      ServerError: false,
      totalData: 0,
      totalRows: 0,
      user: [],
    };
  },
  mounted() {
    if (this.$store.state.currentPage1) {
      this.currentPage = this.$store.state.currentPage1;
      this.getData();
    } else this.getData();
  },
  watch: {
    currentPage() {
      this.$store.commit("changePendCurrentPage", this.currentPage);

      this.getData();
    },
    // search() {
    //   this.getData();
    // },
  },
  methods: {
    getData() {
      this.appLoading = true;
      axios({
        url: "/product/pendingall",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          count: this.count,
          page: this.currentPage,
          keyword: this.search,
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.user = response.data.data;
          this.pages = response.data.pages;
          this.totalData = response.data.count;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    info(pid) {
      this.$router.push({
        name: "productDetails",
        params: { productid: pid },
      });
    },
  },
};
</script>